import "./App.css";
import Contents from "./page/contents";
import React, { useState } from "react";

export default function App() {
  const [uuid,] = useState();


  return (
    <div style={{background:'#efede6',height:'97vh'}}>       
     <h2 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>啥</h2>
      <Contents options={uuid} />
    </div>
  );
}
