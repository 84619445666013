function Lonin(props) {
    function handleLogin() {
        var name = document.getElementById('name').value;
        var passwd = document.getElementById('passwd').value;
        let url = "https://www.zaojingyoutu.top:8002/api/login/";

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: name,
                password: passwd
            }),
        };

        fetch(url, options)
            .then((response) => {
                if (response.status === 200 ) {
                    response.json().then((data) => {
                        if (data.code ===1001) {
                            alert(data.msg);
                        }else{localStorage.setItem('token', data.data.access_token);
                            localStorage.setItem('refreshToken', data.data.refresh_token);
                            props.handleLogin('none');} 
                    });
                } else {
                    alert("登录失败");
                }
            })
    }
    let width = '20%'
    let left = '40%'
    if (
        navigator.userAgent.match(
          /(iPhone|iPad|iPod|Android|Windows Phone|Mobile|BlackBerry|Palm|Tablet|iPad)/
        )
      ) {
        width = "50%";
        left = "25%";
      }
    

    return (
        <>
            <div className="login" style={{ zIndex: "1000", width: width, height: "20%", position: "absolute", top: "45%", left: left, background: "rgba(0,0,0,0.5)", display: "flex" ,
                flexDirection: "column",justifyContent: 'space-evenly',alignItems: "center",borderRadius: "10px"
            }}>
                <input id='name' placeholder="名字"></input>
                <input id='passwd' type="password" placeholder="密码" onKeyDown={(e)=>{if(e.key==='Enter'){handleLogin()}}} ></input>
                <button onClick={handleLogin} >登录</button>
            </div>

        </>
    );
}

export default Lonin;
